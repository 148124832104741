// import icons
import { BsCheck, BsChevronRight } from "react-icons/bs";

// import images
import CourseImage1 from "../src/assets/img/courses/01.jpg";
import CourseImage2 from "../src/assets/img/courses/02.jpg";
import CourseImage3 from "../src/assets/img/courses/03.jpg";

export const navigation = [
    {
        name: "Home",
        href: "#",
    },
    {
        name: "Classes",
        href: "#",
    },
    {
        name: "Features",
        href: "#",
    },
    {
        name: "Contact",
        href: "#",
    },
];

export const facts = [
    {
        startNumber: "80",
        endNumber: "100",
        unit: "",
        title: "Dojo Tersebar",
        desc: " IAI adalah organisasi aikido yang tersebar dengan baik di Indonesia",
    },
    {
        startNumber: "1",
        endNumber: "5",
        unit: "K",
        title: "Aikidoka",
        desc: "Lebih dari 5000 Aikidoka (siswa) dari berbagai Dojo dan kalangan ",
    },
    {
        startNumber: "1",
        endNumber: "50",
        unit: "",
        title: "Variasi Teknik",
        desc: "Aikido mempunyai banyak variasi teknik yang dapat diterapkan",
    },
    {
        startNumber: "1",
        endNumber: "11",
        unit: "",
        title: "Tingkatan",
        desc: "Anda dapat melakukan progress Aikido secara bertahap",
    },
];

export const courses = [
    {
        image: CourseImage1,
        title: "Taisabaki",
        desc: "Taisabaki merupakan teknik gerakan yang melibatkan seluruh anggota badan, seperti senam dan akan berguna untuk penghindaran",
        link: "Info lanjut",
        delay: "600",
    },
    {
        image: CourseImage2,
        title: "Waza | Kata",
        desc: "Waza atau teknik , sedangkan Kata adalah bentuk. Anda akan mempelajari ini sebagai bentuk dari ritme dan harmoni Aikido ",
        link: "Info lanjut",
        delay: "800",
    },
    {
        image: CourseImage3,
        title: "Buki",
        desc: "Buki yang berarti senjata. Anda akan mencoba mempelajari aplikatif Buki dan counter nya antara lain adalah Bo Ken , Jo ,dan Tan to",
        link: "Info lanjut",
        delay: "1000",
    },
];

export const pricing = [
    {
        title: "Junior Class",
        price: "6-15thn",
        list: [
            {
                icon: <BsCheck />,
                name: "Taisabaki",
            },
            {
                icon: <BsCheck />,
                name: "Waza,Kata",
            },
            {
                icon: <BsCheck />,
                name: "Certificate Kyu",
            },
        ],
        buttonText: "Pesan sekarang",
        buttonIcon: <BsChevronRight />,
        delay: "600",
    },
    {
        title: "Regular Class",
        price: "16thn +",
        list: [
            {
                icon: <BsCheck />,
                name: "Taisabaki",
            },
            {
                icon: <BsCheck />,
                name: "Waza,Kata",
            },
            {
                icon: <BsCheck />,
                name: "Nage,Buki",
            },
            {
                icon: <BsCheck />,
                name: "Certificate Kyu",
            },
        ],
        buttonText: "Pesan sekarang",
        buttonIcon: <BsChevronRight />,
        delay: "800",
    },
    {
        title: "Dan Class",
        price: "Kyu 1+",
        list: [
            {
                icon: <BsCheck />,
                name: "Silahkan hubungi kami",
            },
        ],
        buttonText: "Contact us",
        buttonIcon: <BsChevronRight />,
        delay: "900",
    },
];
