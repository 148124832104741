import React, { useState, useEffect, useRef } from 'react';

// import email js
import emailjs from '@emailjs/browser';

{/**REFER TO https://dev.to/ebereplenty/contact-form-with-emailjs-react-581c */}

const Contact = () => {
  const form = useRef();
  const [emailMesssage, setEmailMessage] = useState('');
  const [emailTextColor, setEmailTextColor] = useState('');

  useEffect(() => {
    const timer = setTimeout(() => {
      setEmailMessage('');
    }, 3000);

    // clear timer
    return () => clearTimeout(timer);
  }, [emailMesssage]);

  const sendEmail = (e) => {
    e.preventDefault();

    emailjs
      .sendForm(
        'service_oe69bwj',
        'template_jtx674p',
        form.current,
        'yXOzKSxE5S38zHTvW'// 17 character
      )
      .then(
        (res) => {
          setEmailMessage('Pesan telah dikirim :)');
          setEmailTextColor('green');
        },
        (err) => {
          setEmailMessage('Pesan gagal dikirim :(');
          setEmailTextColor('red');
        }
      );
    e.target.reset();
  };

  return (
      <section id="contact" className="section-sm lg:pt-[250px]">
          <div className="container mx-auto">
              <div
                  className="flex flex-col lg:flex-row lg:gap-x-[74px] bg-contact bg-no-repeat bg-cover min-h-[600px]"
                  data-aos="zoom"
                  data-aos-offset="400"
              >
                  <div
                      className="flex-1 flex flex-col justify-center pl-8"
                      data-aos="fade-down"
                      data-aos-delay="300"
                      data-aos-offset="500"
                  >
                      <h2 className="h2 mb-3 lg:mb-7">
                        Hubungi kami lebih lanjut
                      </h2>
                      <p className="mb-7 lg:mb-0">
                          Hubungi kami di BRID Aikido. 
                          
                      </p>
                      <p className="mb-7 lg:mb-0">
                          BRID memberi kursus yang terbaik dan membantu Anda menemukan
                          latihan yang tepat .
                      </p>
                  </div>
                  <form
                      onSubmit={(e) => sendEmail(e)}
                      ref={form}
                      className="flex-1 bg-white shadow-primary rounded-[20px] p-5 lg:p-10 flex flex-col gap-y-5 max-h-[600px] lg:-mt-20"
                      data-aos="fade-up"
                      data-aos-delay="300"
                      data-aos-offset="500"
                  >
                      <input
                          className="form-control"
                          placeholder="First name"
                          type="text"
                          name="user_firstname"
                          required
                      />
                      <input
                          className="form-control"
                          placeholder="Last name"
                          type="text"
                          name="user_lastname"
                          required
                      />
                      <input
                          className="form-control"
                          placeholder="Email address"
                          type="email"
                          name="user_email"
                          required
                      />
                      <textarea
                          className="form-control py-5 h-[165px] resize-none"
                          placeholder="Message"
                          name="user_message"
                      ></textarea>
                      <button
                          className="btn btn-lg btn-orange self-start"
                          type="submit"
                      >
                          Kirim Pesan
                      </button>
                      <p style={{ color: emailTextColor }}>{emailMesssage}</p>
                  </form>
              </div>
          </div>
      </section>
  );
};

export default Contact;
